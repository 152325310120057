import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import Select from "react-select";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
import Operators from "../../components/OperatorsList/Operators";
import { useDispatch, useSelector } from "react-redux";
import InformationBox from "../../components/InformationBox/InformationBox";
import downloadArrow from "../../assets/img/download-arrow.png";
import nextPrevArrow from "../../assets/img/left-arrow.png";
import angryFace from "../../assets/icons/coloredAngryFace.svg";
import disappointedFace from "../../assets/icons/coloredDisappointed.svg";
import expressionLessFace from "../../assets/icons/coloredExpressionlessFace.svg";
import grinningFace from "../../assets/icons/coloredGrinningFace.svg";
import smilingFace from "../../assets/icons/coloredSmilingFace.svg";
import { firstDay, thisDay, lastDayOfWeek } from "../../utilities/dateUtils";
import constants from "../../utilities/constants";
import {
  getConversationNumber,
  getOperatorsPerDay,
  getOperatorsPerWeek,
  setOperators,
  getResolvedConversations,
  getOperatorsChartMonthly,
  getOperatorsChartDaily,
  getOperatorsChartHourly,
  getOperatorsFeedBack,
  getTotalConversationNumber,
  getOperatorsByDateRange,
  resetTables,
  getUsersRatings,
  changeSelectedValue,
  getConversationsBySpecificRating,
} from "../../slices/statistics";
import { setOperator } from "../../slices/operaters";
import ReactToPdf from "react-to-pdf";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment/locale/fr";
import "moment/locale/ar";
import { DateRange } from "react-date-range";
import { CircularProgress, Popover, Skeleton } from "@mui/material";
import Data from "../../utilities/constants";
import { formatData } from "../../utilities/formatData";
import { formatDate } from "../../utilities/formatDate";
import { openModal } from "../../slices/modals";

const GeneralStats = () => {
  const expressions = [
    angryFace,
    disappointedFace,
    expressionLessFace,
    grinningFace,
    smilingFace,
  ];
  const { t } = useTranslation();
  const handleSelectChange = (e) => {
    dispatch(changeSelectedValue(e));
    !Array.isArray(user) &&
      selectedValue?.label !== e?.label &&
      (() => {
        dispatch(
          getResolvedConversations({
            websiteID: user?.websiteID,
            type: e?.selected.toLowerCase(),
          })
        );
        dispatch(
          getOperatorsFeedBack({
            websiteID: user?.websiteID,
            query: e?.selected.toLowerCase(),
          })
        ).then(
          (res) =>
            !res?.error &&
            (() => {
              // on page load...
              moveProgressBar();
            })()
        );
        dispatch(
          getConversationNumber({
            websiteID: user?.websiteID,
            query: e?.selected.toLowerCase(),
          })
        );
      })();
  };
  const styles = {
    control: (base) => ({
      ...base,
      borderRadius: "8px",
    }),
  };
  moment.locale(localStorage.getItem("i18nextLng"));

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const filterTab = [
    {
      id: 0,
      label: t("Day"),
      onClickHandler: (id) => {
        getOperatorsDay(id);
      },
    },
    {
      id: 1,
      label: t("Week"),
      onClickHandler: (id) => {
        getOperatorsWeek(id);
      },
    },
    {
      id: 2,
      label: t("By Date"),
    },
  ];
  const chartTabButtons = [
    {
      id: 0,
      label: t("Hourly"),
      onClickHandler: (id) => handleClickButton(id),
    },
    {
      id: 1,
      label: t("Daily"),
      onClickHandler: (id) => {
        handleClickButton(id);
      },
    },

    {
      id: 2,
      label: t("Monthly"),
      onClickHandler: (id) => handleClickButton(id),
    },
  ];
  const [clicked, setClicked] = useState(0);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  const [clickedChart, setClickedChart] = useState(1);
  const {
    operatorsPerDay,
    operatorsPerWeek,
    operatorsByDate,
    operators,
    conversationNumber,
    conversationNumberLastWeek,
    conversationNumberStatus,
    resolvedConversationNumberStatus,
    operatorsFeedBackStatus,
    resolvedConversations,
    resolvedConversationsLastPeriod,
    operatorChartMonthlyData,
    operatorChartDailyData,
    operatorChartHourlyData,
    operatorsFeedBackThisWeek,
    operatorsFeedBackLastWeek,
    totalConversationNumber,
    statusgetOperatorsByDateRange,
    ratings,
    selectedValue,
  } = useSelector((state) => state.statistics);
  const [progressPercent, setProgressPercent] = useState(0);

  useEffect(() => {
    // Calculate the progress percentage based on data changes
    const newProgressPercent = (operatorsFeedBackThisWeek / 5) * 100;
    setProgressPercent(newProgressPercent);
  }, [operatorsFeedBackThisWeek]);

  useEffect(() => {
    operatorsFeedBackStatus === "succeeded" &&
      // Move the progress bar whenever the progress percentage changes
      moveProgressBar();

    // Add a window resize event listener to handle progress bar on resize
    window.addEventListener("resize", moveProgressBar);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("resize", moveProgressBar);
    };
  }, [progressPercent]);

  // SIGNATURE PROGRESS
  const moveProgressBar = () => {
    const getPercent = progressPercent / 100;
    const getProgressWrapWidth =
      document.querySelector(".progress-wrap").clientWidth;
    const progressTotal = getPercent * getProgressWrapWidth;
    const animationLength = 1000;

    // Animate the progress bar
    document.querySelector(
      ".progress-bar"
    ).style.transition = `left ${animationLength}ms ease-in-out`;
    document.querySelector(".progress-bar").style.left = `${progressTotal}px`;
  };
  const { operator } = useSelector((state) => state.operators.operator);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const labels =
    localStorage.getItem("i18nextLng") === "ar"
      ? constants.ARABIC_DAYS_LABEL[0]
      : localStorage.getItem("i18nextLng") === "fr"
      ? constants.FRENCH_DAYS_LABEL[0]
      : constants.ENGLISH_DAYS_LABEL[0];
  const [data, setData] = useState({
    labels: labels,
    datasets: [
      {
        label: t("Number of conversations made"),
        backgroundColor: constants.BACKGROUND_COLORS,
        barThickness: 20,
        data: operatorChartHourlyData,
      },
    ],
  });
  useEffect(() => {
    setData({
      labels: labels,
      datasets: [
        {
          label: t("Number of conversations made"),
          backgroundColor: constants.BACKGROUND_COLORS,
          barThickness: 20,
          data: operatorChartHourlyData,
        },
      ],
    });
  }, [localStorage.getItem("i18nextLng")]);
  const currentYear = new Date(Date.now()).getFullYear();
  const [year, setYear] = useState(currentYear);
  useEffect(() => {
    operator &&
      !Array.isArray(user) &&
      dispatch(
        getOperatorsChartDaily({
          websiteID: user.websiteID,
          id: operator?._id,
          date: year + "-" + firstDay.slice(5),
        })
      );
    operator &&
      dispatch(
        getOperatorsChartMonthly({
          websiteID: user?.websiteID,
          query: operator?._id,
          year: year,
        })
      );
    operator &&
      dispatch(
        getOperatorsChartHourly({
          websiteID: user?.websiteID,
          id: operator?._id,
          date: year + "-" + thisDay.slice(5),
        })
      );
    totalConversationNumber === null &&
      !Array.isArray(user) &&
      dispatch(getTotalConversationNumber(user?.websiteID));
  }, [operator, year]);
  useEffect(() => {
    operator &&
      !Array.isArray(user) &&
      setData(() => ({
        labels:
          clickedChart === 0
            ? constants.HOURS_LABELS[0]
            : clickedChart === 1
            ? localStorage.getItem("i18nextLng") === "ar"
              ? constants.ARABIC_DAYS_LABEL[0]
              : localStorage.getItem("i18nextLng") === "fr"
              ? constants.FRENCH_DAYS_LABEL[0]
              : constants.ENGLISH_DAYS_LABEL[0]
            : localStorage.getItem("i18nextLng") === "ar"
            ? constants.ARABIC_MONTHS_LABEL[0]
            : localStorage.getItem("i18nextLng") === "fr"
            ? constants.FRENCH_MONTHS_LABEL[0]
            : constants.ENGLISH_MONTHS_LABEL[0],

        //  constants.MONTHS_LABEL[0],
        datasets: [
          {
            label: t("Number of conversations made"),
            backgroundColor: constants.BACKGROUND_COLORS,
            barThickness: 20,
            data:
              clickedChart === 0
                ? operatorChartHourlyData
                : clickedChart === 1
                ? operatorChartDailyData
                : operatorChartMonthlyData,
          },
        ],
      }));
  }, [
    clickedChart,
    operatorChartHourlyData,
    operatorChartDailyData,
    operatorChartMonthlyData,
  ]);
  useEffect(() => {
    !Array.isArray(user) &&
      dispatch(
        getUsersRatings({
          websiteID: user?.websiteID,
          period: selectedValue?.selected?.toLowerCase(),
        })
      );
  }, [user, selectedValue]);
  useEffect(() => {
    !Array.isArray(user) &&
      (() => {
        resolvedConversations === null &&
          dispatch(
            getResolvedConversations({
              websiteID: user?.websiteID,
              type: selectedValue.label.toLowerCase(),
            })
          );
        operatorsFeedBackThisWeek === null &&
          dispatch(
            getOperatorsFeedBack({
              websiteID: user?.websiteID,
              query: selectedValue.label.toLowerCase(),
            })
          ).then(
            (res) =>
              !res?.error &&
              (() => {
                // on page load...
                moveProgressBar();
              })()
          );

        conversationNumber === null &&
          dispatch(
            getConversationNumber({
              websiteID: user?.websiteID,
              query: selectedValue.label.toLowerCase(),
            })
          );
      })();
  }, [user]);
  useEffect(() => {
    dispatch(setOperators(operatorsPerDay));
  }, []);
  const handleClickButton = (id) => {
    setClickedChart(id);
  };
  useEffect(() => {
    !Array.isArray(user) &&
      dispatch(
        getOperatorsPerDay({
          websiteID: user?.websiteID,
          query: thisDay,
        })
      ).then((res) => {
        !res.error &&
          res.payload.resolvedPerOperator?.length > 0 &&
          dispatch(setOperator(res.payload?.resolvedPerOperator[0]));
      });

    operators.length > 0 && dispatch(setOperator(operators[0]));
    operator &&
      !Array.isArray(user) &&
      dispatch(
        getOperatorsChartHourly({
          websiteID: user?.websiteID,
          id: operator?._id,
          date: thisDay,
        })
      );
  }, [user]);

  const getOperatorsWeek = async (id) => {
    !Array.isArray(user) &&
      dispatch(
        getOperatorsPerWeek({
          websiteID: user?.websiteID,
          startDate: firstDay,
          endDate: lastDayOfWeek,
        })
      ).then((res) => {
        if (res.payload?.resolvedPerOperator?.length > 0) {
          dispatch(setOperator(res?.payload?.resolvedPerOperator[0]));
        }
      });
    dispatch(setOperators(operatorsPerWeek));
    setClicked(id);
  };
  const getOperatorsDay = async (id) => {
    !Array.isArray(user) &&
      dispatch(
        getOperatorsPerDay({
          websiteID: user?.websiteID,
          query: thisDay,
        })
      ).then((res) => {
        if (res.payload?.resolvedPerOperator?.length > 0) {
          dispatch(setOperator(res?.payload?.resolvedPerOperator[0]));
        }
      });
    dispatch(setOperators(operatorsPerDay));
    setClicked(id);
  };
  const getOperatorsByDate = async (id) => {
    !Array.isArray(user) &&
      dispatch(
        getOperatorsByDateRange({
          websiteID: user?.websiteID,
          startDate: formatDate(state[0]?.startDate),
          endDate: formatDate(state[0]?.endDate),
        })
      ).then((res) => {
        res.payload.resolvedPerOperator?.length > 0
          ? dispatch(setOperator(res.payload?.resolvedPerOperator[0]))
          : dispatch(resetTables());
        !res?.error && handleClose();
      });
    dispatch(setOperators(operatorsByDate));
    setClicked(id);
  };
  const ref = React.createRef();
  const options = {
    orientation: "landscape",
    unit: "in",
    format: [10, 15],
  };
  return (
    <div className="analytics-container">
      <div className="title--analytics--page">
        <div>
          <p className="analytics--p">{t("Analytics").replace(" ", "\xa0")}</p>
        </div>
        <div className="btns-container-analytics">
          <div>
            {" "}
            <Select
              value={{ label: t(selectedValue.label) }}
              styles={styles}
              placeholder="Select a filter"
              onChange={handleSelectChange}
              options={Data.ANALYTICS_FILTERS.map((filter) => {
                return {
                  value: filter.id,
                  label: t(filter.label),
                  selected: filter.label,
                };
              })}
            />
          </div>
          <ReactToPdf
            targetRef={ref}
            filename="div-blue.pdf"
            options={options}
            x={1}
            y={1}
            scale={0.7}
          >
            {({ toPdf }) => (
              <div onClick={toPdf} className="download-btn">
                <div>
                  <LazyLoadImage
                    src={downloadArrow}
                    alt="arrow"
                    className="download-arrow"
                  />
                </div>

                <button className="btn-report">{t("Download Report")}</button>
              </div>
            )}
          </ReactToPdf>
        </div>
      </div>
      <div className="top-container-stats" ref={ref}>
        <div className="wrapper-stats">
          <div className="header">
            <div className="container-header">
              {conversationNumberStatus !== "succeeded" ? (
                <Skeleton
                  variant="rectangular"
                  width={403}
                  height={107}
                  style={{ borderRadius: "12px" }}
                  className="skeleton-analytics-card"
                />
              ) : (
                <InformationBox
                  dataThisWeek={conversationNumber}
                  dataLastWeek={conversationNumberLastWeek}
                  description={t("Conversations")}
                  type="conversation"
                  selectedValue={selectedValue}
                />
              )}
              {resolvedConversationNumberStatus !== "succeeded" ? (
                <Skeleton
                  variant="rectangular"
                  width={403}
                  height={107}
                  style={{ borderRadius: "12px" }}
                  className="skeleton-analytics-card"
                />
              ) : (
                <InformationBox
                  dataThisWeek={resolvedConversations}
                  dataLastWeek={resolvedConversationsLastPeriod}
                  description={t("resolved conversations")}
                  type="conversationsResolved"
                  selectedValue={selectedValue}
                />
              )}
              {operatorsFeedBackStatus !== "succeeded" ? (
                <Skeleton
                  variant="rectangular"
                  width={403}
                  height={107}
                  style={{ borderRadius: "12px" }}
                  className="skeleton-analytics-card"
                />
              ) : (
                <InformationBox
                  dataThisWeek={operatorsFeedBackThisWeek}
                  dataLastWeek={operatorsFeedBackLastWeek}
                  description={t("Average user feedback")}
                  type="feedback"
                  selectedValue={selectedValue}
                />
              )}
            </div>
          </div>
          {operatorsFeedBackStatus !== "succeeded" ? (
            <Skeleton
              variant="rectangular"
              width="100%"
              height={107}
              style={{ borderRadius: "8px", marginBottom: "10px" }}
            />
          ) : (
            <div className="ratings-wrapper">
              <div className="ratings-container">
                <div>
                  <h3>{t("Conversation ratings")}</h3>
                  <div>{`${formatData(operatorsFeedBackThisWeek)}/5`}</div>
                </div>
                <div className="emojis-container">
                  {ratings.length > 0 &&
                    ratings.map((el, i) => (
                      <div
                        className="rating"
                        key={i}
                        onClick={() => {
                          dispatch(
                            openModal("info-modal", {
                              id: "conversations-by-rating-modal",
                              websiteID: user?.websiteID,
                              rating: i + 1,
                              user,
                              period: selectedValue?.selected?.toLowerCase(),
                            })
                          );
                          dispatch(
                            getConversationsBySpecificRating({
                              websiteID: user?.websiteID,
                              rating: i + 1,
                              page: 1,
                              period: selectedValue?.selected?.toLowerCase(),
                            })
                          );
                        }}
                      >
                        <div className="emoji-wrapper">
                          <img
                            className="expression-img"
                            src={expressions[i]}
                          />{" "}
                        </div>
                        <span>{el?.count}</span>
                      </div>
                    ))}
                </div>
              </div>
              <div
                className="progress-wrap progress"
                data-progress-percent={(operatorsFeedBackThisWeek / 5) * 100}
              >
                <div class="progress-bar progress"></div>
              </div>
              <div class="progress-bar-percentage">
                <div>0%</div>
                <div>20%</div>
                <div>40%</div>
                <div>60%</div>
                <div>80%</div>
                <div>100%</div>
              </div>
            </div>
          )}

          <div className="operators-statistics">
            <div className="chart-header">
              <div className="chart-title">
                {totalConversationNumber !== null && (
                  <span className="total-conversations-number-container">
                    {" "}
                    <span className="total-conversations-number">
                      {totalConversationNumber}
                    </span>{" "}
                    {t("total conversations number")}
                  </span>
                )}
              </div>
              <div className="chart-year">
                <div
                  className="previous-btn-container"
                  onClick={() =>
                    setYear((prev) => {
                      prev--;
                      return prev;
                    })
                  }
                >
                  <LazyLoadImage
                    src={nextPrevArrow}
                    alt="prev-arrow"
                    className="prev-btn-img"
                  />
                  <button className="previous-btn">{t("Previous")}</button>
                </div>
                <div className="year">{year}</div>
                <div
                  className="next-btn-container"
                  onClick={() =>
                    setYear((prev) => {
                      prev++;
                      return prev;
                    })
                  }
                >
                  <button className="next-btn">{t("Next")}</button>

                  <LazyLoadImage
                    src={nextPrevArrow}
                    alt="next-arrow"
                    className="next-btn-img"
                  />
                </div>
              </div>
              <div className="buttons-container">
                <div className="all-btns">
                  {chartTabButtons.map((el) => (
                    <button
                      onClick={() => el.onClickHandler(el.id)}
                      className={`${
                        el.id === clickedChart
                          ? "chart-btn active-btn"
                          : "chart-btn"
                      }`}
                      key={el.id}
                    >
                      {el.label}
                    </button>
                  ))}
                </div>
              </div>
            </div>
            <div className="chart" id="chartD">
              <Bar
                locale={localStorage.getItem("i18nextLng")}
                options={{
                  responsive: true,
                  maintainAspectRatio: true,
                  legend: { display: false },
                  locale: localStorage.getItem("i18nextLng"),
                  title: {
                    display: true,
                    text: "Operators statistics",
                  },
                }}
                data={data}
              />
            </div>
          </div>
        </div>
        <div className="operators-container">
          <div className="container-operators">
            <div className="top-container">
              <p>{t("Leaderboard")}</p>
              <div className="operators-buttons">
                {filterTab.map((el) =>
                  el.id !== 2 ? (
                    <button
                      key={el.id}
                      onClick={() => el.onClickHandler(el.id)}
                      className={`${
                        el.id === clicked ? "chart-btn active-btn" : "chart-btn"
                      }`}
                    >
                      {el.label}
                    </button>
                  ) : (
                    <button
                      key={el.id}
                      aria-describedby={id}
                      variant="contained"
                      onClick={handleClick}
                      className={`${
                        el.id === clicked ? "chart-btn active-btn" : "chart-btn"
                      }`}
                    >
                      {el.label}
                    </button>
                  )
                )}
              </div>
            </div>
            <Operators users={operators} clicked={clicked} />
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <div className="date-wrapper">
                <DateRange
                  editableDateInputs={true}
                  onChange={(item) => setState([item.selection])}
                  rangeColors={["#6932fa", "#3ecf8e", "#fed14c"]}
                  moveRangeOnFirstSelection={false}
                  ranges={state}
                />
                <button
                  disabled={!state[0]?.startDate || !state[0]?.endDate}
                  className={`date-range-filter-btn`}
                  onClick={() => getOperatorsByDate(2)}
                >
                  {statusgetOperatorsByDateRange === "loading" ? (
                    <CircularProgress
                      style={{
                        width: "25px",
                        height: "25px",
                        color: "white",
                      }}
                    />
                  ) : (
                    t("Filter")
                  )}
                </button>
              </div>
            </Popover>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralStats;
